import Repository from "../Repository";

export default {
  messages: () => {
    return Repository.get('/messages?limit=10')
  },
  update: (id) => {
    return Repository.put("/messages/" + id);
  }
}
